<template>
  <div class="matrix-container">
    <h5>PICO Evidence Matrix</h5>
    <div v-if="isLoading" class="loading-overlay">
      <q-spinner color="primary" size="50px" />
    </div>
    <GipamTable
      v-else
      :rows="formattedPicoData"
      :columns="columns"
      :row-types="getRowTypes()"
      :is-mandatory="isMandatory"
      table-title="PICO Evidence Summary"
    />
  </div>
</template>

<script>
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import EventBus from '@/eventBus.js'
import axios from 'axios'

export default {
  name: 'PicoEvidenceMatrix',
  components: {
    GipamTable,
  },
  data() {
    return {
      assetId: null,
      selectedResults: [],
      formattedPicoData: [],
      columns: [
        { label: 'Countries', field: 'countries' },
        { label: 'Population', field: 'population' },
        { label: 'Intervention', field: 'intervention' },
        { label: 'Comparator', field: 'combinations' },
        { label: 'Outcome(s)', field: 'outcomes' },
        { label: 'Importance', field: 'importance' },
      ],
      isLoading: true, // Add loading state
    }
  },
  computed: {
    isMandatory() {
      // Define if any fields are mandatory
      return this.formattedPicoData.map(() => {
        return {
          countries: true, // example, set to true if mandatory
          outcomes: true, // example, set to true if mandatory
          // Add other fields as necessary
        }
      })
    },
  },
  async mounted() {
    EventBus.on('split-link-clicked', this.openPopup)
    await this.loadAssetId()

    // Fetch all results for consolidated Pico
    const allResultsForConsolidatedPico = await axios.get(
      `jca/allResultsForConsolidatedPico/${this.assetId}`,
    )
    this.formattedPicoData = allResultsForConsolidatedPico.data
    this.isLoading = false
  },
  methods: {
    async loadAssetId() {
      const response = await axios.get('jca/selectedAssetId')
      this.assetId = response.data
    },
    getRowTypes() {
      const rowTypes = {}
      this.formattedPicoData.forEach((row, rowIndex) => {
        rowTypes[rowIndex] = {}
        this.columns.forEach((col) => {
          if (col.field === 'outcomes') {
            rowTypes[rowIndex][col.field] = 'split'
          } else {
            rowTypes[rowIndex][col.field] = 'static'
          }
        })
      })
      return rowTypes
    },
    getEvidenceClass(evidenceType) {
      switch (evidenceType) {
        case 'Randomized controlled trial (RCT)':
          return 'highlight-green'
        case 'Subgroup analysis of RCT data':
        case 'Bayesian network meta-analysis (NMA)':
        case 'Standard NMA':
          return 'highlight-light-green'
        case 'Patient-level adjustment to external controls':
        case 'Population-level adjustment methods':
          return 'highlight-yellow'
        case 'Naïve/unadjusted comparison':
          return 'highlight-orange'
        case 'Other':
        case 'No Evidence/Missing data':
          return 'highlight-red'
        default:
          return ''
      }
    },
    async openPopup({ rowIndex, columnIndex }) {
      const selectedRow = this.formattedPicoData[rowIndex]
      switch (columnIndex) {
        case 0: // 'PICO'
          this.selectedResults = [{ outcome: selectedRow.name }]
          break
        case 1: // 'Countries'
          this.selectedResults = [{ outcome: selectedRow.countries }]
          break
        case 2: // 'Population'
          this.selectedResults = [{ outcome: selectedRow.population }]
          break
        case 3: // 'Intervention'
          this.selectedResults = [{ outcome: selectedRow.intervention }]
          break
        case 4: // 'Comparator'
          this.selectedResults = selectedRow.mergedIds
          break
        case 5: // 'Importance'
          this.selectedResults = [{ outcome: selectedRow.importance }]
          break
        case 6: // 'Type of Evidence'
          this.selectedResults = [{ outcome: selectedRow.evidenceType }]
          break
        default:
          this.selectedResults = []
          break
      }
      this.$emit('open-popup', this.selectedResults)
      try {
        console.log('136 Sending selected IDs:', selectedRow)
        await axios.post('jca/setSelectedMergedIds', selectedRow.mergedIds)
        EventBus.emit('addStudyClicked')
      } catch (error) {
        console.error('Error sending selected IDs:', error)
      }
    },
  },
  beforeUnmount() {
    EventBus.off('cell-clicked', this.openPopup)
  },
}
</script>

<style scoped>
.matrix-container {
  margin-bottom: 20px;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}
</style>
