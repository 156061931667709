<template>
  <q-page class="bg-accent">
    <div class="q-pa-md">
      <q-table
        :class="{ loading: loading }"
        flat
        bordered
        auto-width
        title="Overview of EGPs"
        :rows="egpList"
        :columns="columns"
        row-key="id"
      >
        <template #body-cell-name="props">
          <q-td :props="props">
            <router-link :to="`/BasicData?egpId=${props.row.id}`">
              {{ props.row.name }}
            </router-link>
          </q-td>
        </template>
        <template #body-cell-action="props">
          <q-td :props="props">
            <q-btn
              dense
              flat
              round
              color="grey"
              field="edit"
              icon="edit"
              @click="editItem(props.row)"
            ></q-btn>
          </q-td>
        </template>
        <template #body-cell-selfCheck="props">
          <q-td :props="props">
            <q-btn
              dense
              flat
              round
              color="grey"
              field="selfCheck"
              icon="description"
              @click="selfCheck(props.row)"
            ></q-btn>
          </q-td>
        </template>
        <template #body-cell-monitoring="props">
          <q-td :props="props">
            <q-btn
              dense
              flat
              round
              color="grey"
              field="monitor"
              icon="monitor"
              @click="openMonitoring(props.row)"
            >
            </q-btn>
          </q-td>
        </template>
        <template #body-cell-delete="props">
          <q-td :props="props">
            <q-btn
              dense
              flat
              round
              color="grey"
              field="delete"
              icon="delete"
              @click="onDelete(props.row)"
            >
            </q-btn>
          </q-td>
        </template>
      </q-table>
      <div v-if="loading" class="loading-overlay">
        <q-spinner color="primary" size="50px" />
      </div>
    </div>
    <ErrorDialog
      v-model="errorDialog"
      :message="errorMessage"
      @close-dialog="closeErrorDialog"
    />
  </q-page>
</template>

<script>
import ErrorDialog from '@/core_controls/GipamErrorDialog/GipamErrorDialogView.vue'
import QuestionService from '@/service/QuestionService.ts'
import store from '@/store.js'
import axios from 'axios'

export default {
  name: 'updateEgp',
  components: {
    ErrorDialog,
  },
  data() {
    return {
      nameEgp: '',
      asset: '',
      tpp: '',
      errorDialog: false,
      errorMessage: 'Please complete the questionnaire.',
      columns: [
        {
          name: 'name',
          align: 'center',
          label: 'Name',
          field: 'name',
          sortable: true,
        },
        { name: 'action', align: 'center', label: 'Edit Questions', field: '' },
        {
          name: 'selfCheck',
          align: 'center',
          label: 'Self-Check EGP',
          field: '',
        },
        { name: 'monitoring', align: 'center', label: 'Monitoring', field: '' },
        { name: 'delete', align: 'center', label: 'Delete', field: '' },
      ],
      egpList: [],
      loading: true,
    }
  },
  methods: {
    async onUpdate() {},
    async editItem(item) {
      this.$router.push('/EditQuestions?egpId=' + item.id)
    },
    async selfCheck(item) {
      store.commit('changeEgpId', item.id)
      const lastQuestion = await QuestionService.getCurrentQuestion(25, item.id)
      const selectedAnswers = lastQuestion.answers
        .filter((answer) => answer.isSelected) // Change 'selected' to 'isSelected'
        .map((answer) => answer.id)
      if (selectedAnswers.length === 0) {
        this.errorDialog = true
      } else {
        this.$router.push({
          name: 'selfCheck',
          query: { egpId: item.id, name: item.name },
        })
      }
    },
    async onDelete(item) {
      try {
        var egpId = item.id
        var response = await axios.get('/egp/deleteEgp/' + egpId)
        if (response.data === true) {
          location.reload()
        }
      } catch (error) {
        console.error('Error deleting EGP:', error)
      } finally {
        this.loading = false
      }
    },
    openUploads(item) {
      this.$router.push({
        name: 'documentPage',
        query: { egpId: item.id, name: item.name },
      })
    },
    closeErrorDialog() {
      this.errorDialog = false
    },
    openMonitoring(item) {
      this.$router.push({
        name: 'egpMonitoring',
        query: { egpId: item.id, name: item.name },
      })
    },
  },
  async mounted() {
    try {
      let controllerMethod = '/egp/listEgps'

      let response = await axios.get(controllerMethod)
      this.egpList = response.data
    } catch (err) {
      console.log(err.response)
    } finally {
      this.loading = false
    }
  },
}
</script>
<style>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.loading {
  opacity: 0.5;
}
</style>
