<template>
  <div>
    <h5>Outcome(s)</h5>

    <div>
      <label>Outcome Category:</label>
      <p>{{ selectedCategory }}</p>
    </div>

    <div>
      <label>Definition of Outcome Measure:</label>
      <p>{{ selectedOutcomeMeasure }}</p>
    </div>

    <div>
      <label>Time of Assessment:</label>
      <p>{{ selectedTimeAssessment }}</p>
    </div>

    <div>
      <label>Statistical Measure for Treatment Effect Size:</label>
      <p>{{ selectedStatisticalMeasure }}</p>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import axios from 'axios'

export default {
  name: 'OutcomeForm',
  data() {
    return {
      selectedOutcomeId: 0,
      selectedCategory: '',
      selectedOutcomeMeasure: '',
      selectedTimeAssessment: '',
      selectedStatisticalMeasure: '',
      showFurtherExplanation: false,
    }
  },
  async mounted() {
    EventBus.on(
      'outcome-selected',
      this.fetchSelectedOutcomeForConsolidatedPico,
    )
    await this.fetchSelectedOutcomeForConsolidatedPico() // Call initially
  },
  activated() {
    if (this.selectedOutcomeId) {
      this.fetchOutcomeDetails()
    }
  },
  methods: {
    async fetchSelectedOutcomeForConsolidatedPico() {
      const response = await axios.get(
        'jca/selectedOutcomeIdForConsolidatedPico',
      )
      this.selectedOutcomeId = response.data
      await this.fetchOutcomeDetails()
    },
    async fetchOutcomeDetails() {
      if (!this.selectedOutcomeId) {
        return
      }
      const response = await axios.get(
        `jca/outcomeDetails/${this.selectedOutcomeId}`,
      )

      // Map the response data to component data properties
      this.selectedCategory = response.data.category // "Morbidity"
      this.selectedOutcomeMeasure = response.data.outcomeMeasure // "Proportion of patients in CS-free remission"
      this.selectedTimeAssessment = response.data.timeAssessment // "at week 52"
      this.selectedStatisticalMeasure = response.data.statisticalMeasurement // "RR"
    },
  },
  beforeUnmount() {
    EventBus.off(
      'outcome-selected',
      this.fetchSelectedOutcomeForConsolidatedPico,
    )
  },
}
</script>

<style scoped>
/* Add your styles here */
p {
  margin-top: 5px;
  margin-bottom: 5px;
}
select {
  margin-top: 5px;
}
label {
  margin-right: 10px;
}
</style>
