<template>
  <div class="matrix-container">
    <h5>Results Matrix</h5>
    <div v-if="isLoading" class="loading-overlay">
      <q-spinner color="primary" size="50px" />
    </div>
    <GipamTable
      v-else
      :rows="resultsData"
      :columns="getColumns()"
      :row-types="getRowTypes()"
      :is-mandatory="getIsMandatory()"
      table-title="Results Matrix"
    />
  </div>
</template>

<script>
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import axios from 'axios'

export default {
  name: 'ResultsMatrix',
  components: {
    GipamTable,
  },
  data() {
    return {
      assetId: null,
      countries: [
        'France',
        'Germany',
        'Italy',
        'Spain',
        'Poland',
        'Netherlands',
        'Belgium',
        'Austria',
        'Greece',
        'Czech Republic',
        'Portugal',
        'Hungary',
        'Sweden',
        'Finland',
        'Denmark',
        'Ireland',
        'Slovakia',
        'Lithuania',
        'Slovenia',
        'Latvia',
        'Estonia',
        'Cyprus',
        'Malta',
        'Romania',
        'Bulgaria',
        'Croatia',
      ],
      picoData: [],
      isLoading: true,
    }
  },
  async mounted() {
    await this.loadAssetId()
    await this.generatePICOConsolidation()
    this.isLoading = false
  },

  computed: {
    resultsData() {
      const res = this.picoData.map((pico) => {
        const row = {
          Population: pico.population,
          Intervention: pico.intervention,
          Comparator: pico.combinations,
          Outcomes: pico.outcome,
        }

        // Add evidence data for each country
        this.countries.forEach((country) => {
          // Check if the country is in pico.relevantCountries
          const isRelevant = pico.countries.includes(country)
          if (!isRelevant) {
            row[country] = 'No Evidence/Missing data'
            return row
          } else {
            row[country] = pico.evidenceType
          }
        })
        return row
      })
      return res
    },
  },

  methods: {
    async loadAssetId() {
      const response = await axios.get('jca/selectedAssetId')
      this.assetId = response.data
    },
    async generatePICOConsolidation() {
      const allResultsForConsolidatedPico = await axios.get(
        `jca/allResultsForConsolidatedPicoForResultMatrix/${this.assetId}`,
      )
      this.picoData = allResultsForConsolidatedPico.data
    },
    getColumns() {
      const fixedColumns = [
        { label: 'Population', field: 'Population' },
        { label: 'Intervention', field: 'Intervention' },
        { label: 'Comparator', field: 'Comparator' },
        { label: 'Outcome(s)', field: 'Outcomes' },
      ]

      const countryColumns = this.countries.map((country) => ({
        label: country,
        field: country,
      }))

      return [...fixedColumns, ...countryColumns]
    },
    getRowTypes() {
      const rowTypes = {}
      this.resultsData.forEach((row, rowIndex) => {
        rowTypes[rowIndex] = {}

        // Set types for fixed columns
        rowTypes[rowIndex]['Population'] = 'static'
        rowTypes[rowIndex]['Intervention'] = 'static'
        rowTypes[rowIndex]['Comparator'] = 'static'
        rowTypes[rowIndex]['Outcomes'] = 'static'

        // Set types for country-specific columns
        this.countries.forEach((country) => {
          rowTypes[rowIndex][country] = 'static'
        })
      })
      return rowTypes
    },
    getIsMandatory() {
      const rowTypes = {}
      this.resultsData.forEach((row, rowIndex) => {
        rowTypes[rowIndex] = {}
        this.countries.forEach((country) => {
          rowTypes[rowIndex][country] = true
        })
      })
      return rowTypes
    },
    getEvidenceClass(evidenceType) {
      if (!evidenceType) return ''
      switch (evidenceType) {
        case 'Randomized controlled trial (RCT)':
          return 'highlight-green'
        case 'Subgroup analysis of RCT data':
        case 'Bayesian network meta-analysis (NMA)':
        case 'Standard NMA':
          return 'highlight-light-green'
        case 'Patient-level adjustment to external controls':
        case 'Population-level adjustment methods':
          return 'highlight-yellow'
        case 'Naïve/unadjusted comparison':
          return 'highlight-orange'
        case 'Other':
        case 'No Evidence/Missing data':
          return 'highlight-red'
        default:
          return ''
      }
    },
  },
}
</script>

<style scoped>
.matrix-container {
  margin-bottom: 20px;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}
</style>
