<template>
  <div class="popup">
    <div class="popup-content">
      <div class="centered-content">
        <h5 style="margin-top: 0px">{{ popupTitle }}</h5>
        <img
          :src="getTrafficLightImage()"
          alt="Traffic Light Status"
          class="traffic-light"
        />
        <h7 style="margin: 0px">{{ popupMessage }}</h7>
      </div>
      <ul v-if="missingCategories.length">
        <li v-for="category in missingCategories" :key="category">
          {{ category }}
        </li>
      </ul>
      <q-btn
        @click="closePopup"
        class="add-new-btn neumorphic-btn"
        label="Close"
        color="primary"
      />
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import axios from 'axios'

export default {
  name: 'EvidenceCheckPopup',
  props: {
    popupVisible: {
      type: Boolean,
      required: true,
    },
    outcomes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      missingCategories: [],
      popupTitle: '',
      popupMessage: '',
      trafficLightStatus: '', // Change to trafficLightStatus
    }
  },
  async mounted() {
    await this.checkEvidenceSupport()
  },
  methods: {
    async checkEvidenceSupport() {
      try {
        const response = await axios.get('jca/getEvidenceSupport')
        this.processEvidenceResponse(response.data)
      } catch (error) {
        console.error('Error fetching evidence support:', error)
        this.setErrorState()
      }
    },
    processEvidenceResponse(evidenceState) {
      const greenMatch = evidenceState.match(/Green:/)
      const yellowMatch = evidenceState.match(
        /Yellow:.*Missing data on outcomes for: (.+)/,
      )

      if (greenMatch) {
        this.setGreenState()
      } else if (yellowMatch) {
        this.setYellowState(yellowMatch[1])
      } else {
        this.setErrorState()
      }
    },
    setGreenState() {
      this.popupTitle = 'Evidence Support'
      this.popupMessage = 'PICO fully supported by evidence'
      this.missingCategories = []
      this.trafficLightStatus = 'green' // Set status
    },
    setYellowState(missing) {
      this.popupTitle = 'Evidence Support'
      this.popupMessage = 'PICO partly supported by evidence'
      this.missingCategories = missing
        .split(', ')
        .map((category) => category.trim())
      this.trafficLightStatus = 'yellow' // Set status
    },
    setErrorState() {
      this.popupTitle = 'Evidence Support'
      this.popupMessage = 'Unknown evidence status'
      this.missingCategories = []
      this.trafficLightStatus = 'red' // Set status
    },
    getTrafficLightImage() {
      switch (this.trafficLightStatus) {
        case 'green':
          return require('@/assets/trafficlightGreen.png')
        case 'yellow':
          return require('@/assets/trafficlightYellow.png')
      }
    },
    closePopup() {
      EventBus.emit('close-evidence-popup')
    },
  },
}
</script>

<style scoped>
.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.traffic-light {
  width: 48px; /* Set the desired width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 10px;
}
</style>
