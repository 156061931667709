<template>
  <div>
    <h4>Overall Summary: PICOs & Evidence for the Asset</h4>
    <q-btn
      color="primary"
      class="add-new-btn neumorphic-btn"
      @click="printTable('pico-table')"
      label="Print/Save as PDF/XLS"
    />

    <PicoEvidenceMatrix @openPopup="openPopup" />
    <ResultsMatrix />
    <EvidenceDetailsPopup
      v-if="isPopupVisible"
      :isVisible="isPopupVisible"
      @close="closePopup"
    />
  </div>
</template>

<script>
import EvidenceDetailsPopup from './EvidenceDetailsPopup.vue'
import PicoEvidenceMatrix from './PicoEvidenceMatrix.vue'
import ResultsMatrix from './ResultsMatrix.vue'

export default {
  name: 'PicoSummary',
  data() {
    return {
      isPopupVisible: false,
      selectedResults: [],
    }
  },
  components: {
    PicoEvidenceMatrix,
    ResultsMatrix,
    EvidenceDetailsPopup,
  },
  methods: {
    openPopup(results) {
      this.isPopupVisible = true
      this.selectedResults = results
    },
    closePopup() {
      this.isPopupVisible = false // Hide the popup
    },
    printTable(tableId) {
      // Logic to print/save as PDF/XLS (You can implement this using libraries like jsPDF or SheetJS)
      console.log(`Printing table with ID: ${tableId}`)
      // Add functionality to save or print the table here
    },
  },
}
</script>

<style scoped>
/* Add any styles specific to the PicoSummary component here */
</style>
