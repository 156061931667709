<template>
  <div class="neumorphic-dropdown-country-selector">
    <PICOEvidenceLibrary v-if="!showOutcomes" />
    <OutcomesMasterView v-if="showOutcomes" />
    <StudyStatusCheckPopup v-if="studyStatusCheckPopupVisible" />
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import OutcomesMasterView from './OutcomesMasterView.vue'
import PICOEvidenceLibrary from './PICOEvidenceLibrary.vue'
import StudyStatusCheckPopup from './StudyStatusCheckPopup.vue'

export default {
  name: 'EvidenceLibraryMasterView',
  components: {
    OutcomesMasterView,
    PICOEvidenceLibrary,
    StudyStatusCheckPopup,
  },
  data() {
    return {
      showOutcomes: false,
      studyStatusCheckPopupVisible: false,
    }
  },
  mounted() {
    EventBus.on('addStudyClicked', this.addStudyClicked)
    EventBus.on('submit-study-results-clicked', this.submitStudyResultsClicked)
    EventBus.on('close-evidence-popup', this.closeEvidencePopup)
  },

  methods: {
    addStudyClicked() {
      this.showOutcomes = true
      this.studyStatusCheckPopupVisible = false
    },
    submitStudyResultsClicked() {
      this.showOutcomes = false
      this.studyStatusCheckPopupVisible = true
    },
    closeEvidencePopup() {
      this.studyStatusCheckPopupVisible = false
    },
  },
  beforeUnmount() {
    EventBus.off('addStudyClicked', this.addStudyClicked)
  },
}
</script>
