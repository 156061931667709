<template>
  <div class="country-selector-container">
    <div class="country-selector">
      <label for="country-dropdown">Select a country:</label>
      <select
        id="country-dropdown"
        v-model="currentPicoCountry"
        class="neumorphic-select"
      >
        <option value="" disabled>Select a country</option>
        <option v-for="country in euCountries" :key="country" :value="country">
          {{ country }}
        </option>
      </select>
    </div>
    <q-dialog v-model="showCountrySelector">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Select Country</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="cancelPicoSelection"
          />
        </q-card-section>

        <q-card-section>
          <q-select
            v-model="countryForPicoSelection"
            :options="uniqueCountries"
            label="Select a country"
            @update:model-value="handleCountryChange"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showPICOSelector">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Select PICO</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="cancelPicoSelection"
          />
        </q-card-section>

        <q-card-section>
          <q-table
            style="max-width: 1800px"
            :rows="picosForSelectedCountry"
            :columns="columns"
            row-key="picoNumber"
            virtual-scroll
            :virtual-scroll-sticky-size-start="48"
          >
            <template v-slot:body-cell="props">
              <q-td :props="props" style="white-space: pre-line">
                <div v-html="props.value"></div>
              </q-td>
            </template>

            <template v-slot:body-cell-actions="props">
              <q-td>
                <q-btn
                  color="primary"
                  class="add-new-btn neumorphic-btn"
                  flat
                  dense
                  icon="edit"
                  @click="selectPICO(props.row)"
                >
                  Select
                </q-btn>
              </q-td>
            </template>
          </q-table>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="form-actions" v-if="showButtons">
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        label="Create New PICO"
        @click="emitCreateNewPICO"
        :disabled="!currentPicoCountry"
      />
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        label="Edit Existing PICO"
        @click="editExistingPico"
        :disabled="!currentPicoCountry"
      />
      <q-btn
        color="primary"
        class="add-new-btn neumorphic-btn"
        label="Select PICO from Another Country"
        @click="createNewPicoFromExisting"
        :disabled="!currentPicoCountry"
      />
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import axios from 'axios'
export default {
  name: 'CountrySelectorComponent',

  data() {
    return {
      currentPicoCountry: null,
      euCountries: [],
      picos: [],
      showButtons: true,
      assetId: null,
      editPico: false,
      picoId: null,
      showCountrySelector: false,
      showPICOSelector: false,
      uniqueCountries: [],
      columns: [
        {
          name: 'picoNumber',
          field: 'picoNumber',
          align: 'left',
        },
        { name: 'country', label: 'Country', field: 'country', align: 'left' },
        {
          name: 'population',
          label: 'Population',
          field: 'population',
          align: 'left',
        },
        {
          name: 'intervention',
          label: 'Intervention',
          field: 'intervention',
          align: 'left',
        },
        {
          name: 'combinations',
          label: 'Comparator',
          field: 'combinations',
          align: 'left',
        },

        {
          name: 'outcomes',
          label: 'Outcome(s)',
          field: 'outcomes',
          align: 'left',
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'actions',
          align: 'center',
        },
      ],
    }
  },
  watch: {
    countryForPicoSelection(newCountry) {
      this.setCurrentPicoCountry(newCountry)
    },
    async currentPicoCountry() {
      if (this.picoId && this.picoId != -1 && this.currentPicoCountry) {
        await this.setSelectedPicoid(-1)
        this.emitEvent('show-confirmation-modal', null)
      }
    },
  },
  mounted() {
    EventBus.on(
      'show-pico-management-actions-buttons',
      this.setButtonVisibility,
    )
    EventBus.on('current-pico-country', this.setCurrentPicoCountry)

    this.loadEuCountries()
    this.loadAssetId()
  },
  computed: {
    picosForSelectedCountry() {
      return this.picos
    },
  },
  methods: {
    async loadEuCountries() {
      const response = await axios.get('jca/euCountries')
      this.euCountries = response.data
    },
    async loadAssetId() {
      const response = await axios.get('jca/selectedAssetId')
      this.assetId = response.data
    },
    async fetchSelectedPicoId() {
      axios.get('jca/selectedPicoId').then((response) => {
        this.picoId = response.data
      })
    },

    handleCountryChange(newCountry) {
      if (this.editPico) {
        this.setCurrentPicoCountry(newCountry)
      } else {
        this.countryForPicoSelection = newCountry
      }

      this.showPICOSelector = true
      this.showCountrySelector = false
    },

    async handleSpecifyAnotherPICOForAntoherCountry() {
      await this.setSelectedPicoid(null)
      this.setCurrentPicoCountry(null)
      this.setButtonVisibility(true)
    },
    async setSelectedPicoid(picoId) {
      await axios.post(`/jca/setSelectedPicoId/${picoId}`)
    },
    emitEvent(event, payload) {
      EventBus.emit(event, payload)
    },

    async editExistingPico() {
      await this.getPicosForCountry(this.currentPicoCountry)
      this.editPico = true
      this.showPICOSelector = true
    },
    onDialogClose() {
      this.showCountrySelector = false
    },
    async createNewPicoFromExisting() {
      const picoResponse = await axios.get(`jca/fetchPicos/${this.assetId}`)
      this.picos = picoResponse.data
      this.uniqueCountries = [
        ...new Set(this.picos.map((pico) => pico.country)),
      ]
      this.editPico = false
      this.showCountrySelector = true
    },
    cancelPicoSelection() {
      this.showPICOSelector = false
      this.setButtonVisibility(true)
    },
    setButtonVisibility(showButtons) {
      this.showButtons = showButtons
    },
    setCurrentPicoCountry(currentPicoCountry) {
      this.currentPicoCountry = currentPicoCountry
    },
    async selectPICO(pico) {
      this.showPICOSelector = false
      this.showCountrySelector = false
      this.picoId = pico.id
      if (!this.editPico) {
        await this.copyPICO(pico)
      }
      await this.setSelectedPicoid(this.picoId)

      this.setButtonVisibility(false)
      this.emitEvent('new-pico-selected', null)
    },
    handleTabNavigation(tabName) {
      this.setButtonVisibility(true)
      if (tabName === 'pico-consolidation') {
        this.$emit('navigate-to-basic-data')
      }
      this.activeTab = tabName // Switch to the specified tab
    },
    async emitCreateNewPICO() {
      this.getMaxVisualizationId()
      await this.savePicoRequest()
      await this.setSelectedPicoid(this.picoId)
      this.setButtonVisibility(false)
      this.emitEvent('new-pico-selected', null)
    },
    async copyPICO() {
      this.getMaxVisualizationId()
      this.copyPicoRequest()
    },
    async savePicoRequest() {
      try {
        const response = await axios.post('jca/savePico', {
          assetId: this.assetId,
          country: this.currentPicoCountry,
          visualizationId: this.maxVisualizationId,
        })
        this.picoId = response.data
      } catch (error) {
        console.error(
          'Error creating new PICO:',
          error.response ? error.response.data : error.message,
        )
      }
    },
    async copyPicoRequest() {
      try {
        const response = await axios.post('jca/copyPico', {
          assetId: this.assetId,
          country: this.currentPicoCountry,
          visualizationId: this.maxVisualizationId,
          oldPicoId: this.picoId,
        })
        this.picoId = response.data
        await this.setSelectedPicoid(this.picoId)
      } catch (error) {
        console.error(
          'Error creating new PICO:',
          error.response ? error.response.data : error.message,
        )
      }
    },
    getMaxVisualizationId() {
      axios.get('jca/getMaxVisualizationId').then((response) => {
        this.maxVisualizationId = response.data + 1
      })
    },

    async getPicosForCountry(country) {
      axios
        .get('jca/fetchPicosForCountry/' + country + '/' + this.assetId)
        .then((response) => {
          this.picos = response.data
        })
    },
  },
  beforeUnmount() {
    EventBus.off(
      'show-pico-management-actions-buttons',
      this.setButtonVisibility,
    )
    EventBus.off('current-pico-country', this.setCurrentPicoCountry)
  },
}
</script>
<style scoped>
@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 800px;
  }
}
</style>
