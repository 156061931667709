<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <q-btn
        icon="close"
        flat
        round
        dense
        v-close-popup
        @click="$emit('close')"
      />
      <h3>Select Other Results</h3>
      <q-table
        style="max-width: 1800px"
        :rows="results"
        :columns="columns"
        row-key="studyName"
        virtual-scroll
        :virtual-scroll-sticky-size-start="48"
      >
        <template v-slot:body-cell="props">
          <q-td :props="props" style="white-space: pre-line">
            <div>
              {{ props.value }}
            </div>
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td>
            <q-btn
              color="primary"
              class="add-new-btn neumorphic-btn"
              flat
              dense
              icon="edit"
              @click="selectOutcome(props.row)"
            >
              Select
            </q-btn>
          </q-td>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'SelectOtherResults',
  data() {
    return {
      showPICOSelector: false,
      results: [], // This will be populated with data from the backend
      selectedOutcome: null,
      columns: [
        {
          name: 'studyName',
          label: 'Study Name',
          align: 'left',
          field: 'studyName',
        },
        {
          name: 'currentTypeOfEvidence',
          label: 'Type of Evidence',
          align: 'left',
          field: 'currentTypeOfEvidence',
        },
        {
          name: 'relativeEffectSize',
          label: 'Effect Size',
          align: 'right',
          field: 'relativeEffectSize',
        },
        { name: 'pValue', label: 'P-Value', align: 'right', field: 'pValue' },
        {
          name: 'confidenceInterval',
          label: 'Confidence Interval',
          align: 'left',
          field: 'confidenceInterval',
        },
        {
          name: 'dataFinality',
          label: 'Data Finality',
          align: 'center',
          field: 'dataFinality',
        },
        { name: 'actions', label: 'Actions', align: 'center' },
      ],
    }
  },
  async mounted() {
    await this.fetchOutcomes() // Fetch outcomes when the component mounts
  },
  methods: {
    async fetchOutcomes() {
      try {
        const response = await axios.get('jca/resultsForConsolidatedPico')
        this.results = response.data
      } catch (error) {
        console.error('Error fetching outcomes:', error)
      }
    },
    async selectOutcome(outcome) {
      this.selectedOutcome = outcome
      this.showPICOSelector = false
      await axios.post(`/jca/SelectedOutcomeResult/${outcome.id}`)
      this.$emit('close')
    },
    cancelPicoSelection() {
      this.showPICOSelector = false // Close the dialog without selection
    },
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.outcomes-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.outcomes-table th,
.outcomes-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.outcomes-table tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}

.outcomes-table .selected {
  background-color: #e0e0e0; /* Highlight selected row */
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
}
</style>
