<template>
  <div style="padding: 20px">
    <div class="text-h6">Select Country</div>
    <q-space />

    <q-select
      v-model="selectedCountry"
      :options="uniqueCountries"
      label="Select a country"
      @update:model-value="handleCountryChange"
    />

    <div v-if="selectedCountry" class="output">
      <div v-if="picosForSelectedCountry.length > 0">
        <h5>Evidence needs in {{ selectedCountry }}</h5>
        <q-table
          :rows="picosForSelectedCountry"
          :columns="columns"
          row-key="picoNumber"
          virtual-scroll
          :virtual-scroll-sticky-size-start="48"
        >
          <template v-slot:body-cell="props">
            <q-td :props="props" style="white-space: pre-line">
              <div v-html="props.value"></div>
            </q-td>
          </template>
        </q-table>
      </div>
      <div v-else>
        <p>No PICOs available for {{ selectedCountry }}.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PICOOverview',
  data() {
    return {
      assetId: null,
      picoOverview: null,
      showCountrySelector: false,
      selectedCountry: null,
      columns: [
        {
          name: 'picoNumber',
          field: 'picoNumber',
          align: 'left',
        },
        {
          name: 'population',
          label: 'Population',
          field: 'population',
          align: 'left',
        },
        {
          name: 'intervention',
          label: 'Intervention',
          field: 'intervention',
          align: 'left',
        },
        {
          name: 'combinations',
          label: 'Comparator',
          field: 'combinations',
          align: 'left',
        },
        {
          name: 'outcomes',
          label: 'Outcome(s)',
          field: 'outcomes',
          align: 'left',
        },
      ],
    }
  },
  computed: {
    uniqueCountries() {
      return this.picoOverview
        ? [...new Set(this.picoOverview.map((data) => data.country))]
        : []
    },
    picosForSelectedCountry() {
      if (!this.selectedCountry || !this.picoOverview) return []
      const countryData = this.picoOverview.find(
        (data) => data.country === this.selectedCountry,
      )

      if (countryData) {
        return countryData.picos.map((pico) => {
          return {
            ...pico,
          }
        })
      }

      return []
    },
  },
  async mounted() {
    await this.loadAssetId()
    this.generatePICOOverview()
    this.showCountrySelector = true
  },
  methods: {
    async generatePICOOverview() {
      try {
        const picoResponse = await axios.get(`jca/fetchPicos/${this.assetId}`)
        const picos = picoResponse.data
        console.log('picos', picos)

        // Group PICOs by country
        const groupedPicos = picos.reduce((acc, pico) => {
          const {
            country,
            description,
            population,
            intervention,
            outcomes,
            combinations,
          } = pico

          // Split combinations into an array
          const combinationArray = combinations ? combinations.split(', ') : []

          // Create a new entry for each combination
          combinationArray.forEach((combination) => {
            if (!acc[country]) {
              acc[country] = {
                country,
                picos: [],
              }
            }

            acc[country].picos.push({
              picoNumber: acc[country].picos.length + 1,
              description,
              population,
              intervention,
              outcomes,
              combinations: combination, // Set the current combination
            })
          })

          return acc
        }, {})

        // Convert the grouped object to an array
        this.picoOverview = Object.values(groupedPicos)
      } catch (error) {
        console.error('Error fetching PICOs:', error)
      }
    },
    async loadAssetId() {
      const response = await axios.get('jca/selectedAssetId')
      this.assetId = response.data
    },
    handleCountryChange(value) {
      this.selectedCountry = value
      this.showCountrySelector = false
    },
  },
}
</script>
