<template>
  <div
    class="module-content neumorphic-container"
    style="background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.12)"
  >
    <div class="text-primary text-h4 mb-4 text-center">Asset Overview</div>
    <q-tabs v-model="activeSection" dense>
      <q-tab name="basic-data" label="Basic Data" />
      <q-tab name="pico-per-country" label="PICO per Country" />
      <q-tab name="pico-consolidation" label="PICO Consolidation" />
      <q-tab name="evidence-library" label="Evidence Library" />
      <q-tab name="summary" label="Summary" />
    </q-tabs>

    <q-tab-panels
      v-model="activeSection"
      style="background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.12)"
    >
      <q-tab-panel name="basic-data">
        <AssetBasicDataForm />
      </q-tab-panel>
      <q-tab-panel name="pico-per-country">
        <PICOCountrySelector />
      </q-tab-panel>
      <q-tab-panel name="pico-consolidation">
        <PICOConsolidationMaster />
      </q-tab-panel>
      <q-tab-panel name="evidence-library">
        <PICOEvidenceLibrary />
      </q-tab-panel>
      <q-tab-panel name="summary">
        <PICOSummary />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import PICOConsolidationMaster from '../PICO/Consolidation/PICOConsolidationMaster.vue'
import PICOCountrySelector from '../PICO/CountrySelector/PICOCountrySelectorMasterView.vue'
import PICOEvidenceLibrary from '../PICO/EvidenceLibrary/EvidenceLibraryMasterView.vue'
import PICOSummary from '../PICO/Summary/PICOSummaryMasterView.vue'
import AssetBasicDataForm from './BasicDataForm.vue'

export default {
  name: 'AssetOverview',
  components: {
    AssetBasicDataForm,
    PICOCountrySelector,
    PICOConsolidationMaster,
    PICOEvidenceLibrary,
    PICOSummary,
  },
  data() {
    return {
      activeSection: 'basic-data', // Default to Basic Data tab
    }
  },
  async mounted() {
    EventBus.on('asset-tab-navigation', this.handleTabNavigation) // Set up the listener
  },
  methods: {
    handleTabNavigation(tabName) {
      this.activeSection = tabName // Switch to the specified tab
    },
  },
  beforeUnmount() {
    EventBus.off('asset-tab-navigation', this.handleTabNavigation) // Clean up the listener
  },
}
</script>

<style lang="scss">
@import '../jcaStyle.scss';
</style>
