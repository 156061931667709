<template>
  <q-td style="padding: 0px">
    <div class="split-outcome-cell">
      <p style="margin-bottom: 0px">Show all outcomes</p>
      <q-icon
        class="toggle-icon"
        :name="isOutcomeContentVisible(index) ? 'expand_less' : 'expand_more'"
        @click="toggleOutcomeContent(index)"
        aria-hidden="true"
      />
      <div v-if="isOutcomeContentVisible(index)" class="collapsible-content">
        <div
          v-for="(outcome, outcomeIndex) in outcomes"
          :key="outcomeIndex"
          class="evidence-item"
        >
          <StaticCellView :content="outcome.outcome" />
          <q-icon
            class="toggle-icon"
            :name="
              isContentVisible(outcomeIndex) ? 'expand_less' : 'expand_more'
            "
            @click="toggleContent(outcomeIndex)"
            aria-hidden="true"
          />
          <div
            v-if="
              outcome.evidenceTypes &&
              outcome.evidenceTypes.length > 0 &&
              isContentVisible(outcomeIndex)
            "
            class="collapsible-content-inner"
          >
            <div
              v-for="(evidenceType, i) in outcome.evidenceTypes"
              :key="i"
              class="evidence-item"
            >
              <StaticCellView :content="evidenceType" @click="handleClick" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-td>
</template>

<script>
import StaticCellView from './GipamStaticCellView.vue'

export default {
  props: {
    outcomes: {
      type: Array,
      required: true,
    },
  },
  components: {
    StaticCellView,
  },
  data() {
    return {
      visibleContent: Array(this.outcomes.length).fill(false),
      visibleOutcomeContent: Array(this.outcomes.length).fill(false),
    }
  },
  methods: {
    handleClick() {
      this.$emit('split-link-clicked')
    },
    toggleContent(index) {
      this.visibleContent[index] = !this.visibleContent[index]
    },
    toggleOutcomeContent(index) {
      this.visibleOutcomeContent[index] = !this.visibleOutcomeContent[index]
    },
    isContentVisible(index) {
      return this.visibleContent[index]
    },
    isOutcomeContentVisible(index) {
      return this.visibleOutcomeContent[index]
    },
  },
}
</script>

<style scoped>
.split-outcome-cell {
  display: flex;
  justify-content: space-between;
  position: relative; /* Ensure the parent is relative for absolute positioning */
}

.toggle-icon {
  cursor: pointer;
  margin-left: 8px; /* Space between StaticCellView and icon */
}

.collapsible-content {
  position: absolute; /* Keep it absolute */
  top: 100%; /* Position it below the row */
  background-color: #f9f9f9; /* Background color */
  z-index: 2; /* Ensure it appears above */
  align-items: flex-start; /* Align items at the start */
}

.collapsible-content-inner {
  position: absolute; /* Absolute positioning for inner content */
  left: calc(-300%); /* Position it to the left of the collapsible-content */
  top: 0; /* Align it at the same vertical level */
  background-color: #fff; /* Inner content background */
  border-right: 1px solid #ccc; /* Optional border for separation */
  padding: 5px; /* Add padding */
  z-index: 3; /* Ensure it appears above */
}

.evidence-item {
  border-bottom: 1px solid #ddd; /* Optional border for evidence items */
  padding: 5px; /* Optional padding */
  display: flex;
  justify-content: space-between;
}
</style>
