<template>
  <div class="country-selector-container">
    <div class="country-selector">
      <label for="country-dropdown">Type of Outcome:</label>
    </div>
    <select
      id="country-dropdown"
      v-model="selectedOutcome"
      @change="onOutcomeChange"
      class="neumorphic-select"
    >
      <option value="" disabled>Select an outcome</option>
      <!-- Default option -->
      <option
        v-for="outcome in outcomes"
        :key="outcome.outcomeId"
        :value="outcome.outcomeId"
      >
        {{ outcome.name }}
        <!-- Ensure this matches your DTO -->
      </option>
    </select>
  </div>
  <div class="pico-form neumorphic-container">
    <q-tabs v-model="activeTab" dense>
      <q-tab name="outcomes" label="Select Outcomes" />
      <q-tab name="results" label="Select Results per outcome" />
    </q-tabs>

    <q-tab-panels v-model="activeTab">
      <q-tab-panel name="outcomes">
        <Outcome />
      </q-tab-panel>

      <q-tab-panel name="results">
        <OutcomeResults />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import EventBus from '@/eventBus'
import axios from 'axios'
import Outcome from './Outcome.vue'
import OutcomeResults from './OutcomeResults.vue'

export default {
  name: 'PICOForm',
  components: { Outcome, OutcomeResults },
  data() {
    return {
      activeTab: 'outcomes',
      selectedOutcome: null,
      outcomes: [],
    }
  },
  async mounted() {
    await this.fetchConsolidatedPicoIds()
    await this.fetchOutcomes()
  },
  methods: {
    async onOutcomeChange() {
      if (this.selectedOutcome) {
        await axios.post(
          `/jca/setSelectedOutcomeForConsolidatedPico/${this.selectedOutcome}`,
        )
        EventBus.emit('outcome-selected')
      } else {
        console.warn('No outcome selected.') // Warn if undefined
      }
    },
    async fetchOutcomes() {
      try {
        if (this.consolidatedPicoIds.length > 0) {
          const outcomesResponse = await axios.get(
            `jca/getOutcomesForConsolidatedPicoIds`,
          )
          this.outcomes = outcomesResponse.data // Ensure this is an array of OutcomeDto
        } else {
          console.warn('No consolidated PICO IDs available.')
        }
      } catch (error) {
        console.error('Error fetching outcomes:', error)
      }
    },
    async fetchConsolidatedPicoIds() {
      const response = await axios.get('jca/getConsolidatedPicoIds')
      this.consolidatedPicoIds = response.data
    },
  },
}
</script>

<style scoped>
@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 800px;
  }
}
</style>
