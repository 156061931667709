<template>
  <div>
    <div
      v-if="picoConsolidation && picoConsolidation.length > 0"
      class="output"
    >
      <h4>PICO Evidence Library</h4>

      <!-- Filter Input for Population -->
      <div>
        <q-input
          v-model="populationFilter"
          label="Filter by Population"
          debounce="300"
          @input="filterTable"
        />
      </div>

      <q-table
        :rows="filteredPicoConsolidation"
        :columns="columns"
        row-key="picoNumber"
        virtual-scroll
        :virtual-scroll-sticky-size-start="48"
      >
        <template v-slot:body-cell-comparator="props">
          <q-td :props="props">{{ props.value[0] }}</q-td>
        </template>
        <template v-slot:body-cell-outcomes="props">
          <q-td :props="props">
            <ul>
              <li v-for="outcome in props.value" :key="outcome">
                {{ outcome }}
              </li>
            </ul>
          </q-td>
        </template>
        <template v-slot:body-cell-status="props">
          <q-td :props="props" @click="openEvidenceCheckPopup()">
            <img
              :src="getTrafficLightImage(props.row.trafficLightStatus)"
              alt="Traffic Light Status"
              class="traffic-light"
              @click="checkEvidenceSupport(props.row)"
            />
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              color="primary"
              class="add-new-btn neumorphic-btn"
              @click="addStudyClicked(props.row)"
              label="Add / revise study to Evidence Library"
            />
          </q-td>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import axios from 'axios'

export default {
  name: 'EvidenceLibrary',
  data() {
    return {
      assetId: '',
      picoConsolidation: [],
      populationFilter: '',
      columns: [
        {
          name: 'picoNumber',
          field: 'picoNumber',
          align: 'left',
        },
        {
          name: 'population',
          label: 'Population',
          field: 'population',
          align: 'left',
        },
        {
          name: 'intervention',
          label: 'Intervention',
          field: 'intervention',
          align: 'left',
        },
        {
          name: 'comparator',
          label: 'Comparator',
          field: 'combinations',
          align: 'left',
        },
        {
          name: 'outcomes',
          label: 'Outcome(s)',
          field: 'outcomes',
          align: 'left',
        },
        { name: 'status', label: 'Status', align: 'center', field: 'status' },
        { name: 'actions', label: 'Actions', align: 'left' },
      ],
    }
  },
  computed: {
    filteredPicoConsolidation() {
      return this.picoConsolidation // Implement filtering if necessary
    },
  },
  async mounted() {
    await this.loadAssetId()
    await this.generatePICOConsolidation()
  },
  methods: {
    openEvidenceCheckPopup() {
      EventBus.emit('submit-study-results-clicked')
    },

    async checkEvidenceSupport(row) {
      try {
        // Ensure row.mergedIds is an array
        const response = await axios.get(
          'jca/getEvidenceSupportForEvidenceLibrary',
          {
            params: { picoIds: row.mergedIds },
            paramsSerializer: (params) => {
              return Object.keys(params)
                .map((key) => {
                  return Array.isArray(params[key])
                    ? params[key]
                        .map(
                          (val) =>
                            `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
                        )
                        .join('&')
                    : `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
                })
                .join('&')
            },
          },
        )

        const status = this.processEvidenceResponse(response.data)

        // Directly update the row's traffic light status
        row.trafficLightStatus = status // Set the status directly
      } catch (error) {
        console.error('Error fetching evidence support:', error)
        row.trafficLightStatus = 'red' // Default to red if there's an error
      }
    },
    async generatePICOConsolidation() {
      const picoResponse = await axios.get(
        `jca/generatePicoConsolidation/${this.assetId}`,
      )
      this.picoConsolidation = picoResponse.data

      // Check evidence support for each row after loading data
      for (const row of this.picoConsolidation) {
        await this.checkEvidenceSupport(row)
      }
    },
    processEvidenceResponse(evidenceState) {
      const greenMatch = evidenceState.match(/Green:/)
      const yellowMatch = evidenceState.match(
        /Yellow:.*Missing data on outcomes for: (.+)/,
      )

      if (greenMatch) {
        return 'green' // Fully supported
      } else if (yellowMatch) {
        return 'yellow' // Partly supported
      } else {
        return 'red' // Not supported
      }
    },
    getTrafficLightImage(status) {
      switch (status) {
        case 'green':
          return require('@/assets/trafficlightGreen.png')
        case 'yellow':
          return require('@/assets/trafficlightYellow.png')
      }
    },
    async addStudyClicked(selectedRow) {
      if (!selectedRow.mergedIds || selectedRow.mergedIds.length === 0) {
        alert('No IDs available for this PICO.')
        return
      }

      try {
        await axios.post('jca/setSelectedMergedIds', selectedRow.mergedIds)
        EventBus.emit('addStudyClicked')
      } catch (error) {
        console.error('Error sending selected IDs:', error)
      }
    },
    async loadAssetId() {
      const response = await axios.get('jca/selectedAssetId')
      this.assetId = response.data
    },
  },
}
</script>

<style scoped>
.output {
  margin: 20px 0;
}
.traffic-light {
  width: 24px; /* Set the desired width */
  height: auto; /* Maintain aspect ratio */
  cursor: pointer;
}
</style>
