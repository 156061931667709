<template>
  <q-page class="bg-accent">
    <div class="q-pa-md">
      <q-table
        flat
        bordered
        title="Overview"
        :rows="topicsWithAnsweredQuestions"
        :columns="columns"
        row-key="name"
        hide-bottom
        :rows-per-page-options="[0]"
      >
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th auto-width />
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td auto-width>
              <q-btn
                size="sm"
                color="primary"
                round
                dense
                @click="props.expand = !props.expand"
                :icon="props.expand ? 'remove' : 'add'"
              />
            </q-td>
            <q-td v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.value }}
            </q-td>
          </q-tr>
          <q-tr v-show="props.expand" :props="props">
            <q-td> </q-td>
            <q-td>
              <div v-for="frage in props.row.fragen" :key="frage.id">
                <div v-if="frage.answers.length > 0" class="row q-pa-xs">
                  <div
                    class="col-8 text-left"
                    v-tooltip="{ content: frage.text }"
                  >
                    {{ frage.lfdNr }}: {{ frage.editQuestionText }}
                    <q-tooltip>
                      {{ frage.text }}
                    </q-tooltip>
                  </div>
                  <div class="col-3">
                    <div
                      v-for="answer in frage.answers"
                      :key="answer.id"
                      style="height: 37px; line-height: 37px; padding: 5px"
                    >
                      {{ answer.answerText }}
                    </div>
                  </div>
                  <div class="col-1">
                    <div v-for="answer in frage.answers" :key="answer.id">
                      <div class="q-pl-md q-gutter-sm" style="padding: 5px">
                        <q-btn
                          color="primary"
                          round
                          size="sm"
                          icon="edit"
                          @click="gotoQuestion(frage.id)"
                        />
                        <q-btn
                          v-if="answer.templateId"
                          color="primary"
                          round
                          size="sm"
                          icon="dataset"
                          @click="gotoTemplateForQuestion(answer.templateId)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </q-page>
</template>

<script>
import router from '@/router'
import store from '@/store.js'
import axios from 'axios'

const columns = [
  {
    name: 'Question',
    required: true,
    label: 'Question',
    align: 'left',
    field: (row) => row.name,
    format: (val) => `${val}`,
    sortable: true,
  },
]

export default {
  setup() {
    return {
      columns,
    }
  },
  data() {
    return {
      topics: [],
    }
  },
  mounted() {
    const egpId = this.$route.query.egpId
    this.loadQuestionsAndAnswersForEgp(egpId)
    store.commit('changeEgpId', egpId)
  },
  computed: {
    topicsWithAnsweredQuestions() {
      return this.topics.filter((topic) =>
        topic.fragen.some((frage) => frage.answers.length > 0),
      )
    },
  },
  methods: {
    async loadQuestionsAndAnswersForEgp(egpId) {
      try {
        const response = await axios.get('/egp/getEgpDetails/' + egpId)
        const transformedTopics = response.data.map((topic) => ({
          name: topic.name,
          fragen: topic.questions.map((question) => ({
            id: question.id,
            lfdNr: question.lfdNr,
            editQuestionText: question.editQuestionText,
            text: question.text,
            answers: question.answers.map((answer) => ({
              answerText: answer.answerText,
              templateId: answer.templateId,
            })),
            order: question.order,
          })),
        }))
        this.topics = transformedTopics
      } catch (error) {
        console.error('Fehler beim Laden der Daten:', error)
      }
    },
    async gotoQuestion(frageId) {
      store.commit('changeFrageId', frageId)
      router.push('/Questionnaire')
    },
    async gotoTemplateForQuestion(templateId) {
      const egpId = this.$route.query.egpId
      router.push('/EditTemplate?egpId=' + egpId + '&templateId=' + templateId)
    },
  },
}
</script>
