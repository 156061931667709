<template>
  <div class="popup-overlay">
    <div class="popup-content">
      <q-btn icon="close" flat round dense v-close-popup @click="closePopup" />
      <h5>Evidence Details</h5>
      <div v-if="isLoading" class="loading-overlay">
        <q-spinner color="primary" size="50px" />
      </div>
      <GipamTable
        v-if="tableData.length > 0"
        :rows="tableData"
        :columns="columns"
        :row-types="getRowTypes()"
        :is-mandatory="isMandatory"
        table-title="Evidence Summary"
      />
      <div v-else>No data available</div>
    </div>
  </div>
</template>

<script>
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import axios from 'axios'

export default {
  name: 'EvidenceDetailsPopup',
  components: {
    GipamTable,
  },

  data() {
    return {
      tableData: [],
      columns: [
        { label: 'Outcome Measure', field: 'outcomeMeasure' },
        { label: 'Effect Size Measure', field: 'relativeEffectSize' },
        { label: 'Point Estimate', field: 'pValue' },
        { label: 'Statistical Inference', field: 'confidenceInterval' },
      ],
      isLoading: true,
    }
  },
  computed: {
    isMandatory() {
      return this.tableData.map(() => {
        return {
          outcomeMeasure: true,
          relativeEffectSize: true,
          pValue: true,
          confidenceInterval: true,
        }
      })
    },
  },
  async mounted() {
    await this.fetchOutcomeDetails()
  },
  methods: {
    closePopup() {
      this.$emit('close')
    },
    async fetchOutcomeDetails() {
      const response = await axios.get(
        `jca/evidenceDetailsForConsolidatedPicos`,
      )
      this.tableData = response.data.map((item) => ({
        category: item.category,
        outcomeMeasure: item.outcomeMeasure,
        relativeEffectSize: item.relativeEffectSize,
        pValue: item.pValue,
        confidenceInterval: item.confidenceInterval,
      }))
      this.isLoading = false
    },
    getRowTypes() {
      const rowTypes = {}
      this.tableData.forEach((row, rowIndex) => {
        rowTypes[rowIndex] = {}
        this.columns.forEach((col) => {
          rowTypes[rowIndex][col.field] = 'static'
        })
      })
      return rowTypes
    },
  },
}
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 90%; /* Limit the width */
  max-height: 80%; /* Limit the height */
  width: 500px; /* Set a default width */
  height: auto; /* Allow auto height */
  overflow: auto; /* Enable scrolling for overflow content */
}

button {
  margin-bottom: 10px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}
</style>
