<template>
  <div style="padding: 20px">
    <div
      v-if="!(picoConsolidation && picoConsolidation.length > 0)"
      class="output"
    >
      Consolidation is loading...
    </div>
    <div
      v-if="picoConsolidation && picoConsolidation.length > 0"
      class="output"
    >
      <!-- Filter Input for Population -->
      <div>
        <q-input
          v-model="populationFilter"
          label="Filter by Population"
          debounce="300"
          @input="filterTable"
        />
      </div>

      <q-table
        :rows="filteredPicoConsolidation"
        :columns="columns"
        row-key="picoNumber"
        virtual-scroll
        :virtual-scroll-sticky-size-start="48"
      >
        <template v-slot:body-cell-comparator="props">
          <q-td :props="props">
            {{ props.value[0] }}
          </q-td>
        </template>
        <template v-slot:body-cell-outcomes="props">
          <q-td :props="props">
            <ul>
              <li v-for="outcome in props.value" :key="outcome">
                {{ outcome }}
              </li>
            </ul>
          </q-td>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PICOConsolidation',
  data() {
    return {
      assetId: '',
      picoConsolidation: [],
      consolidationHistory: [],
      isAdmin: false,
      populationFilter: '',
      columns: [
        {
          name: 'picoNumber',
          field: 'picoNumber',
          align: 'left',
        },
        {
          name: 'population',
          label: 'Population',
          field: 'population',
          align: 'left',
        },
        {
          name: 'intervention',
          label: 'Intervention',
          field: 'intervention',
          align: 'left',
        },
        {
          name: 'comparator',
          label: 'Comparator',
          field: 'combinations',
          align: 'left',
        },
        {
          name: 'outcomes',
          label: 'Outcome(s)',
          field: 'outcomes',
          align: 'left',
        },
        {
          name: 'relevantCountries',
          label: 'Relevant Countr(y)ies',
          field: 'relevantCountries',
          align: 'left',
          format: (val) => val.join(', '),
        },
        {
          name: 'relevantPopulationPercentage',
          label: 'Relevant for % of EU Population',
          field: 'relevantPopulationPercentage',
          align: 'left',
          format: (val) => `${parseFloat(val).toFixed(2)}%`,
        },
        {
          name: 'populationBasedRelevance',
          label: 'Population-based Relevance',
          field: 'populationBasedRelevance',
          align: 'left',
        },
      ],
    }
  },
  computed: {
    filteredPicoConsolidation() {
      // Filter based on the population filter input
      return this.picoConsolidation.filter((pico) =>
        pico.population.toString().includes(this.populationFilter),
      )
    },
  },
  async mounted() {
    await this.loadAssetId()
    await this.generatePICOConsolidation()
  },
  methods: {
    async generatePICOConsolidation() {
      try {
        const picoResponse = await axios.get(
          `jca/generatePicoConsolidation/${this.assetId}`,
        )
        this.picoConsolidation = picoResponse.data
      } catch (error) {
        console.error('Error fetching PICOs:', error)
      }
    },
    async loadAssetId() {
      try {
        const response = await axios.get('jca/selectedAssetId')
        this.assetId = response.data
      } catch (error) {
        console.error('Error loading asset ID:', error)
      }
    },
  },
}
</script>
